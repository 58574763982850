

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
   
    
        <div class="row g-2" >
            <div class="col-md-5 mx-auto">

     <div class="row">
      <div class="col-12">
            <Notification />
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Change Password</h4>
          
          </div>
           <b-alert v-model="regError" class="mt-3" variant="danger" dismissible>
                
                       <li v-for="data in regError " :key="data">
            {{ data }}
     </li>
                    </b-alert>
          <!-- end card header -->
          <div class="card-body">
            
            <div class="live-preview">
              <form action="javascript:void(0);">
                <div class="mb-3">
                  <label for="phone" class="form-label"
                    >Password</label
                  >
                  <input
                    type="password"
                    
                    v-model="password"
                    class="form-control"
                    id="phone"
                    placeholder="Password"
                  />
                </div>
            
               <div class="mb-3">
                  <label for="amount" class="form-label"
                    >New Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="newpassword"
                    id="amount"
                    placeholder="New Password"
                  />
                </div>
                  <div class="mb-3">
                  <label for="amount" class="form-label"
                    >Confirm New Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="confirmpassword"
                    id="amount"
                    placeholder="Confirm New Password"
                  />
                </div>
                <div class="text-end">
                  <button type="submit" class="btn btn-primary" @click="changepassword" :disabled="load">
                    <div class="spinner-border spinner-border-sm" role="status" v-if="load" > 
  <span class="sr-only">Loading...</span>
</div>
                    Continue
                  </button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
   
    </div>
                
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
    
</template>






<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";



export default {
    page: {
        title: "Change Password",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
          
            title: "Change Password",
             load: false,
            regError: null,
            password: null,
            newpassword:null,
            confirmpassword:null,
          
          
        
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
     
  
    },

    methods: {
             async changepassword() {
                 this.load = true;
            await axios.post(process.env.VUE_APP_MAIN_URL + "/auth/change/password", {password: this.password, newpassword: this.newpassword, newpassword_confirmation: this.confirmpassword}).catch((error) => {

              if (error.response.status == 420) {
                   Swal.fire("Error!", error.response.data.data.message, "error");
              } 
                  else if (error.response.status == 422) {
       var data = JSON.parse(error.response.data.data)
    var html = [];
    this.load = false;
    for (var k in data) {
        if (Object.prototype.hasOwnProperty.call(data, k)) {
               html.push(data[k][0]);
       
        }
    }
    
             return this.regError = html;

    } else {
                   Swal.fire("Error!", "Please Check Your Internet Connection", "error");
              }
  


              this.load = false;
             
                throw error;

            }).then( async () => {
             var data = await this.$store.dispatch('userlogout');  

              this.load = false;
             if (data == true) {
                   Swal.fire("Password Changed!", "Password has been changed successfully", "success");
                      this.$router.push({name: 'dashboard'});
             }

               

            })
        },
  
    },
    
};
</script>
